.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutProduct__info {
    padding-left: 20px;
}

    .checkoutProduct__info > button {
        background: #f0c14b;
        border: 1px solid;
        margin-top: 10px;
        border-color: #a88734 #9c7e31 #846a29;
        Color: #111;
    }

.CheckoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkoutProduct__rating {
    display: flex;
}

.checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
}
